<template>
  <div data-vue-component-name="XsysTv">
    <ButtonBack @click="onBack" />

    <transition name="fade" mode="out-in" appear>
      <h1 :key="activeListItem">
        {{ activeListItem?.name || 'XSYS TV' }}
      </h1>
    </transition>

    <img
      src="backgrounds/xsys-tv.jpg"
      alt="xsys tv background image"
    >

    <transition name="fade" mode="out-in">
      <XsysTvSingleView
        v-if="activeListItem"
        :item="activeListItem"
      />

      <XsysTvMultipleView
        v-else
        @set-active-item="toggleActiveListItem"
      />
    </transition>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ButtonBack } from '@xsys2/components';
import { metaController } from '@/modules';
import { isNull } from '@xsys2/functions';

metaController({
  title: 'Xsys Tv Page',
  link: [
    {
      rel: 'preload',
      href: 'backgrounds/xsys-tv.jpg',
      as: 'image',
    },
  ],
});

const router = useRouter();
const store = useStore();
store.dispatch('xsysTv/getData');

const activeListItem = ref(null);
const toggleActiveListItem = item => (activeListItem.value = item);

const onBack = () => {
  if (isNull(activeListItem.value)) {
    router.push({ name: 'Home' });
  } else {
    toggleActiveListItem(null);
  }
};
</script>

<style lang="scss" scoped>
[data-vue-component-name="XsysTv"] {
  width: 100%;
  height: 100%;
  padding: 32px;
  color: #fff;

  [data-vue-component-name="ButtonBack"] {
    margin-bottom: 32px;
  }

  h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 0.1em;
    margin-bottom: 24px;
  }

  img {
    @include absolute-full;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(20px);
    z-index: -1;
    background-position: center;
    object-position: center top;
  }

  [data-vue-component-name="XsysTvSingleView"] {}

  [data-vue-component-name="XsysTvMultipleView"] {}
}
</style>
